<template>
    <div class="vld-parent">
        <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        :height="height"
        :width="width"
        loader="dots"
        color="#44a3d4"></loading>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        data() {
            return {
                isLoading: false,
                fullPage: true,
                width: 100,
                height: 100,
            }
        },
        components: {
            Loading
        },
    }
</script>

<style lang="scss" scoped>

</style>