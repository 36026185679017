<template>
    <vue-base64-file-upload
            class="upload-wrapper"
            accept="image/png,image/jpeg,image/gif,application/pdf,application/x-pdf,application/x-bzpdf,
                    application/x-gzpdf,application/msword,vnd.openxmlformats-officedocument. wordprocessingml.document,
                    application/msexcel,vnd.openxmlformats-officedocument. spreadsheetml.sheet,text/plain"
            input-class="upload-input"
            :max-size="this.customMaxFileSize"
            disable-preview
            :placeholder="placeholder"
            @size-exceeded="onSizeExceeded"
            @file="onFile"
            @load="onLoad"/>
</template>

<script>
    import VueBase64FileUpload from 'vue-base64-file-upload';

    export default {
        components: {
            VueBase64FileUpload
        },
        props: {
            placeholder: String,
        },
        data() {
            return {
                customMaxFileSize: 3, // megabytes
                file: null,
            };
        },
        methods: {
            onFile( file ) {
                // write file object to data (for accessing via onLoad method below)
                this.file = file;
            },
            onLoad( dataUri ) {
                // emit 'uploaded' event to parent and pass file meta data and datauri
                this.$emit( 'uploaded', {
                    'file': { 'name': this.file.name, 'type': this.file.type },
                    'data': dataUri } );
            },
            onSizeExceeded( size ) {
                alert( `File ${size} MB size exceeds limits of ${this.customMaxFileSize} MB! Please tell Mario ;-)` );
            }
        },
    }
</script>

<style lang="scss" scoped>
    .upload-wrapper {
        &:not(:last-child) {
            margin-right: 1rem;
        }
    }
</style>