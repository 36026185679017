import { decodeHTML, showSuccessPopup } from '@/utils/utils';
import { addOrUpdateCustomer, addStaffcalcDraft } from '@/api/data';
import { updateCustomFixture } from '@/utils/form-helper';

/**
 * Do the actual submit.
 *
 * @param instance
 * @param form_data
 */
export function doSubmit(instance, form_data) {
    // show loading dots
    instance.$refs.loadingComponent.isLoading = true;

    // append 'real' domain to relative/local URL '/media/...' to https://id-cs.com/media/ (e.g.)
    const signature_text = decodeHTML(instance.currentUser.mailsig).replace('/media/',
        `${process.env.VUE_APP_IC_SERVICE_HOST}/media/`);
    const mailtext = instance.$refs.rtEditorComponent.editor.getHTML() + signature_text;

    updateCustomFixture(instance);

    const customer = {
        'mail_address': form_data.email,
        'is_company': form_data.isCompany,
        'gender': form_data.gender,
        'title_prefixed': form_data.titlePrefixed,
        'title_suffixed': form_data.titleSuffixed,
        'first_name': form_data.firstName,
        'last_name': form_data.lastName,
        'company': form_data.company,
        'street': form_data.street,
        'city': form_data.city,
        'phone_number': form_data.phone,
        'mobile_number': form_data.mobile,
        'zip_code': form_data.zip.toString(),
        'extra_address_line': '',
        'sales_channel': instance.options.saleschannels.find(channel => channel['erpId'] === form_data.sales_channel),
    }

    if (form_data.isCompany) {
        customer['fleet_size'] = form_data.fleetSize;
    }

    // add or update customer
    // create and init pending offer
    addOrUpdateCustomer(customer).then(customer_resp => {
        const cid = parseInt(customer_resp['customer_id']);
        const draft_data = {
            'additional_infos': form_data.additionalInfos,
            'add_signing_page': form_data.template_options.add_signing_page,
            'add_service_description': form_data.template_options.add_service_description,
            'add_terms_and_conditions': form_data.template_options.add_terms_and_conditions,
            'customer_mailtext': mailtext,
            'additional_attachments': form_data.additionalAttachments,
            'price_request': instance.price_request,
            'customer_id': cid,
            'fixture_saved_desc': instance.options.fixtureDetails,
        }

        if (form_data.tags) {
            draft_data['lead_tags'] = form_data.tags.map(tag => tag.id);
        } else {
            draft_data['lead_tags'] = [];
        }

        addStaffcalcDraft(draft_data).then(() => {
            // hide loading dots
            instance.$refs.loadingComponent.isLoading = false;
            showSuccessPopup('Info',
                `Das Angebot an ${customer.mail_address} wurde erfolgreich verschickt.`);
        });
    });
}
