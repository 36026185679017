import Vue from 'vue'
import App from './App.vue'

// import global sass file
import '@/assets/sass/app.scss';

Vue.config.productionTip = false

if (process.env.NODE_ENV === 'production') {
  require('./utils/sentry-integration');
}

new Vue({
  render: h => h(App),
}).$mount('#app');
