import { getCookie } from '@/utils/utils';

/**
 * Perform a get request via built-in fetch().
 *
 * @param url
 * @param json
 * @returns {Promise<Response>}
 */
export function getData(url = '', json = true) {
    url = process.env.VUE_APP_IC_SERVICE_HOST + url;

    return fetch(url,{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    }).then(response => json ? response.json(): response);
}

/**
 * Post data to django including CSRF_TOKEN using the fetch API.
 *
 * @param url
 * @param data
 * @returns {Promise<Response>}
 */
export function postData(url = '', data = '') {
    url = process.env.VUE_APP_IC_SERVICE_HOST + url;
    const csrftoken = getCookie('csrftoken');

    return fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify(data),
        credentials: 'include',
    }).then(response => response.json());
}
