import { getData, postData } from '@/api/api';
import { showErrorPopup } from '@/utils/utils';

export function getPrice(data) {
    return postData('/service/public/price/', data)
        .catch((error) => {
            console.error(error);
            showErrorPopup('Fehler beim Abrufen des Preises', error);
        });
}

/**
 * Wrapper for getting cars via IC Service.
 *
 * @returns {Promise<Response | void>}
 */
export function getCars(country_code) {
    return getData(`/service/cars/${country_code}/?fields=id,model_variant,model_year&staffcalc-available=1`)
        .catch((error) => {
            console.error(error);
            showErrorPopup(`Fehler beim Abrufen der Fahrzeuge aus ${country_code}`, error);
        });
}

/**
 * Wrapper for getting colors via IC Service
 *
 * @param car_id
 * @returns {Promise<Response>}
 */
export function getCar(car_id) {
    const fields = [
        'colors',
        'default_color_id',
        'initial_payment_amounts',
        'initial_payment_amount_default',
        'additionalfixtures',
        'fixturedetails',
    ];

    return getData(`/service/cars/${car_id}/?fields=${fields.join(',')}`)
        .catch((error) => {
            console.error(error);
            showErrorPopup(`Fehler beim Abrufen des Fahrzeugs #${car_id}`, error);
        });
}

/**
 * Wrapper for getting offerstore settings.
 *
 * @param country_code
 * @returns {Promise<Response>}
 */
export function getOfferstoreSettings(country_code) {
    return getData(`/offerstore/settings/${country_code}/?fields=default_sc_customer_mail_text`)
        .catch((error) => {
            console.error(error);
            showErrorPopup('Fehler beim Abrufen der Offerstore Settings', error);
        });
}

export function getServiceGlobals(country_code) {
    return getData(`/service/globals/${country_code}/?fields=instantly_available`)
        .catch((error) => {
           console.error(error);
           showErrorPopup('Fehler beim Abrufen der IC-Service Globals', error);
        });
}

export function getUser() {
    return getData('/staffcalc/current-user/', false)
        .catch((error) => {
            console.error(error);
            showErrorPopup('Fehler beim Abrufen des Users', error);
        });
}

export function getLeads() {
    return getData('/staffcalc/erp/leads/')
        .catch((error) => {
            console.error(error);
            showErrorPopup('Fehler beim Abrufen der ERP Leads', error);
        });
}

export function getSalesChannels(country_code) {
    return getData(`/offerstore/saleschannels/${country_code}/`)
        .catch((error) => {
            console.error(error);
            showErrorPopup('Fehler beim Abrufen der Saleschannels', error);
        });
}

export function getLead(lead_id) {
    return getData(`/staffcalc/erp/lead/${lead_id}/`)
        .catch((error) => {
            console.error(error);
            showErrorPopup(`Fehler beim Abrufen des Leads #${lead_id}`, error);
        });
}

export function getTags() {
    return getData('/staffcalc/erp/leads/tags/')
        .catch((error) => {
            console.error(error);
            showErrorPopup('Fehler beim Abrufen der Lead Tags', error);
        });
}

export function getCustomers() {
    return getData('/staffcalc/erp/customers/')
        .catch((error) => {
            console.error(error);
            showErrorPopup('Fehler beim Abrufen der ERP Kunden', error);
        });
}

export function getCustomer(customer_id) {
    return getData( `/staffcalc/erp/customer/${customer_id}/`)
        .catch((error) => {
            console.error(error);
            showErrorPopup(`Fehler beim Abrufen des ERP Kunden #${customer_id}`, error);
        });
}

export function getAFPrices(price_request) {
    return postData('/service/public/price/optionals/', price_request)
        .catch((error) => {
            console.error(error);
            showErrorPopup('Fehler beim Abrufen der Zusatzausstattungspreise', error);
        });
}

export function getChargingEquip(car_id, country_code) {
    return getData(`/service/public/charging-equipment/${country_code}/${car_id}/`)
        .catch((error) => {
            console.error(error);
            showErrorPopup('Fehler beim Abrufen des Lade-Equipments.', error);
        });
}

export function addOrUpdateCustomer(customerdata) {
    return postData('/offerstore/customer/', customerdata)
        .catch((error) => {
            console.error(error);
            showErrorPopup('Fehler beim Anlegen/Updaten des Offerstore Kunden', error);
        });
}

export function addStaffcalcDraft(draftdata) {
    return postData('/offerstore/sc-offer/', draftdata)
        .catch((error) => {
            console.error(error);
            showErrorPopup('Fehler beim Erstellen des Staffcalc-Drafts', error);
        });
}

export function getCustomAF(data) {
    return postData('/staffcalc/custom-af/', data)
        .catch((error) => {
            console.error(error);
            showErrorPopup('Fehler beim Abrufen der manuellen Zusatzausstattung.', error);
        });
}
