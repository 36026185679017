import swal from 'sweetalert';

/**
 *  Get a cookie by name.
 *
 *  @param name
 *  @returns {string|null}
 */
export function getCookie(name) {
    if (!document.cookie) {
        return null;
    }

    const token = document.cookie.split(';')
        .map(c => c.trim())
        .filter(c => c.startsWith(name + '='));

    if (token.length === 0) {
        return null;
    }

    return decodeURIComponent(token[0].split('=')[1]);
}

/**
 * Show the confirmation popup.
 *
 * @param title
 * @param text
 */
export function showConfirmPopup(title, text) {
    const content_elem = document.createElement('div');
    content_elem.innerHTML = text;

    return swal({
        title: title,
        content: content_elem,
        icon: 'warning',
        buttons: true,
        dangerMode: true
    }).catch(error => console.error(error));
}

/**
 * Show a success info popup.
 *
 * @param title
 * @param text
 */
export function showSuccessPopup(title, text) {
    const content_elem = document.createElement('div');
    content_elem.innerHTML = text;

    return swal({
        title: title,
        content: content_elem,
        icon: 'success',
    }).catch(error => console.error(error));
}

/**
 * Show error popup.
 *
 * @param title
 * @param text
 * @returns {Promise<any>}
 */
export function showErrorPopup(title, text) {
    const content_elem = document.createElement('div');
    content_elem.innerHTML = text;

    return swal({
        title: title,
        content: content_elem,
        icon: 'error',
    }).catch(error => console.error(error));
}

/**
 * Decode HTML markup.
 * @see https://gomakethings.com/decoding-html-entities-with-vanilla-javascript/
 *
 * @param encoded_html
 * @returns {string}
 */
export function decodeHTML(encoded_html) {
    const txt = document.createElement('textarea');
    txt.innerHTML = encoded_html;

    return txt.value;
}

/**
 * Build the delivery String.
 * Same logic as on server.
 * @TODO: maybe get rid of this and retrieve the string.
 *
 * @param deliveryTime
 * @param iaInterval
 */
export function getDeliveryString(deliveryTime, iaInterval) {
    if (deliveryTime['days'] === null || deliveryTime['days'] === undefined) {
        return 'Auf Anfrage';
    }

    if (deliveryTime['days'] <= iaInterval) {
        return 'Sofort';
    }

    const weeks = Math.round(deliveryTime['days'] / 7);

    return weeks === 1 ? `${weeks} Woche` : `${weeks} Wochen`;
}

/**
 * Find an extra item in extra options by name.
 *
 * @param extra_options
 * @param extra
 * @return {*}
 */
export function findExtraOption(extra_options, extra) {
    return extra_options.find(extraItem => extraItem.name === extra);
}

/**
 * Get the currency symbol according to country.
 *
 * @param c country code ('eng', 'de', 'at', ...)
 * @return {string}
 */
export function getCurrSymbol(c) {
    if (c === 'eng') {
        return '£';
    }

    if (c === 'ch') {
        return 'CHF';
    }

    return '€';
}

/**
 * Get the currency name according to country.
 *
 * @param c country code ('eng', 'de', 'at', ...)
 * @return {string}
 */
export function getCurrName(c) {
    if ( c === 'eng' ) {
        return 'Pfund';
    }

    if ( c === 'ch' ) {
        return 'Franken'
    }

    return 'Euro';
}

/**
 * Get the saved fixture description object by car and color.
 *
 * @param car car object
 */
export function getFixtureDesc(car) {
    const res = {};

    if (car.fixturedetails.first_level_items) {
        res.level1 = car.fixturedetails.first_level_items;
    }

    if (car.fixturedetails.second_level_items) {
        res.level2 = car.fixturedetails.second_level_items;
    }

    if (car.fixturedetails.third_level_items) {
        res.level3 = car.fixturedetails.third_level_items;
    }

    return res;
}
