<!--
    Grid Component based on @see https://vuejs.org/v2/examples/grid-component.html
-->
<template>
    <table>
        <thead>
          <tr>
              <th v-for="key in columns" :key="key" @click="sortBy( key )" :class="{ active: sortKey === key }">
                  {{ key | transformHeadings }}
                  <span class="arrow" :class="sortOrders[ key ] > 0 ? 'asc' : 'dsc'"></span>
              </th>
          </tr>
        </thead>
        <tbody>
            <tr v-for="entry in filteredHeroes" :key="entry.key">
                <td v-for="key in columns" :key="key">
                    <template v-if="entry[ key ]">
                        <span v-if="key !== 'pdf' && key !== 'mail'">{{ entry[ key ] | formatLocale }}</span>
                        <a v-if="key === 'pdf'" :href="buildPDFLink( entry[ key ] )" target="_blank">
                            <icon name="pdf"/>
                        </a>
                        <a v-if="key === 'mail'" :href="buildMailLink( entry[ key ] )" target="_blank">
                            <icon name="pdf"/>
                        </a>
                    </template>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    import Icon from './Icon.vue'
    import { DateTime } from 'luxon';

    export default {
        components: { Icon },
        props: {
            heroes: Array,
            columns: Array,
            filterKey: String,
            pdfDest: String,
            mailDest: String,
        },
        data: function () {
            const sortOrders = {};

            this.columns.forEach( function ( key ) {
                sortOrders[ key ] = 1
            } );

            return {
                sortKey: '',
                sortOrders: sortOrders
            }
        },
        computed: {
            filteredHeroes: function () {
                const sortKey = this.sortKey;
                const filterKey = this.filterKey && this.filterKey.toLowerCase();
                const order = this.sortOrders[ sortKey ] || 1;
                let heroes = this.heroes;

                if ( filterKey ) {
                    heroes = heroes.filter( function ( row ) {
                        return Object.keys( row ).some( function ( key ) {
                            return String( row[ key ] ).toLowerCase().indexOf( filterKey ) > -1;
                        } );
                    } );
                }

                if ( sortKey ) {
                    heroes = heroes.slice().sort( function ( a, b ) {
                        a = a[ sortKey ];
                        b = b[ sortKey ];

                        return ( a === b ? 0 : a > b ? 1 : -1 ) * order;
                    } );
                }
                return heroes;
            }
        },
        filters: {
            transformHeadings: function ( str ) {
                switch ( str ) {
                    case 'car':
                        return 'Fahrzeug';
                    case 'date':
                        return 'Datum';
                    case 'months':
                        return 'Laufzeit';
                    case 'km':
                        return 'Jahreskilometer/meilen';
                    case 'ip':
                        return 'Anzahlung';
                    case 'pdf':
                        return 'PDF';
                    case 'mail':
                        return 'Mail';
                }
            },
            formatLocale: function ( str ) {
                if ( Number.isInteger( str ) ) {
                    return parseInt( str ).toLocaleString();
                } else if ( DateTime.fromISO( str ).isValid ) {
                    return DateTime.fromISO( str ).toLocaleString();
                } else {
                    return str;
                }
            },
        },
        methods: {
            sortBy: function ( key ) {
                this.sortKey = key;
                this.sortOrders[ key ] = this.sortOrders[ key ] * -1;
            },
            buildPDFLink: function ( key ) {
                return this.pdfDest + key;
            },
            buildMailLink: function ( key ) {
                return this.mailDest + key;
            }
        }
    }
</script>

<style lang="css" scoped>
    table {
        border: 2px solid #44a3d4;
        border-radius: 3px;
        background-color: #fff;
    }

    th {
        background-color: #44a3d4;
        color: rgba(255, 255, 255, 0.66);
        cursor: pointer;
        user-select: none;
    }

    td {
        background-color: #f9f9f9;
    }

    th, td {
        min-width: 120px;
        padding: 10px 20px;
    }

    th.active {
        color: #fff;
    }

    th.active .arrow {
        opacity: 1;
    }

    .arrow {
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 0;
        margin-left: 5px;
        opacity: 0.66;
    }

    .arrow.asc {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 4px solid #fff;
    }

    .arrow.dsc {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid #fff;
    }
</style>
